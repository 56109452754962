<template>
  <ion-page id="bannerDetail">
    <ion-content :fullscreen="true">
      <topbar
        :class="{
          'gradient30 fixed': !$store.state.isPC,
          'top-bar-white': $store.state.isPC,
        }"
        :bigtitle="false"
        :showBack="true"
        :showClose="false"
        :share="false"
      ></topbar>
      <div class="pc-box margin-top-0 pc-box2">
        <div class="pc-min-content-pct">
          <div class="title-PC2" style="padding-top: 130px" v-if="$store.state.isPC">
            {{ name }}
          </div>
          <div class="detail-content" style="position: relative">
            <div class="max-600" style="margin-bottom: 30px">
              <iframe
                v-if="video"
                :src="video"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                :style="
                  $store.state.isPC
                    ? 'top: 0; left: 0; width: 100%;height: 30vw'
                    : 'top: 0; left: 0; width: 100%;height: 56vw'
                "
              ></iframe>
              <img
                :style="$store.state.isPC ? 'border-radius: 20px;' : ''"
                v-if="coverImg"
                v-lazy="coverImg"
                :key="coverImg"
              />
            </div>
            <div
              class="bannerText"
              :class="{
                banner4: bannerType == 4 && !$store.state.isPC,
                pc: $store.state.isPC,
              }"
              v-html="text"
            ></div>
            <img
              v-if="bannerType == 4"
              class="goRecipientBtn"
              :class="{ marginbtn: !$store.state.isPC }"
              src="../assets/selectRecipent.png"
              @click="goSelectRecipent"
            />
            <p class="banner4link pc" v-if="$store.state.isPC && bannerType == 4">
              Read the
              <a target="_blank" href="https://www.ritchies.com.au/ritchiescardterms"
                >Terms & Conditions</a
              >
              of our community benefit program
            </p>
            <p class="banner4link" v-else-if="!$store.state.isPC && bannerType == 4">
              Read the
              <span @click="openBrowser('https://www.ritchies.com.au/ritchiescardterms')"
                >Terms & Conditions</span
              >
              of our community benefit program
            </p>
          </div>
          <div class="height50"></div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "bannerDetail",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar },
  data() {
    return {
      video: "",
      text: "",
      coverImg: "",
      name: "",
      type: "",
    };
  },
  methods: {
    goSelectRecipent() {
      this.$router.push({
        path: "/community",
      });
      this.sendGAEvent("Click", "Banner Detail", "Community");
    },
  },
  ionViewWillEnter() {
    this.video = this.$store.state.form.bannerInfo.video;
    this.text = this.$store.state.form.bannerInfo.text;
    this.coverImg = this.$store.state.form.bannerInfo.coverImg;
    this.name = this.$store.state.form.bannerInfo.name;
    this.bannerType = this.$store.state.form.bannerInfo.bannerType;
    this.sendGAPageView("Banner Detail" + this.name);
    this.$store.state.exitApp = false;

    // this.video = this.$route.query.video;
    // this.text = this.$route.query.text;
    // this.coverImg = this.$route.query.coverImg;
    // this.name = this.$route.query.name;
  },
};
</script>
